import { render, staticRenderFns } from "./accidentStatus.vue?vue&type=template&id=0f176766&scoped=true"
import script from "./accidentStatus.vue?vue&type=script&lang=js"
export * from "./accidentStatus.vue?vue&type=script&lang=js"
import style0 from "./accidentStatus.vue?vue&type=style&index=0&id=0f176766&lang=sass"
import style1 from "./accidentStatus.vue?vue&type=style&index=1&id=0f176766&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f176766",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\SHMS\\FrontEnd\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f176766')) {
      api.createRecord('0f176766', component.options)
    } else {
      api.reload('0f176766', component.options)
    }
    module.hot.accept("./accidentStatus.vue?vue&type=template&id=0f176766&scoped=true", function () {
      api.rerender('0f176766', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sop/iim/status/accidentStatus.vue"
export default component.exports